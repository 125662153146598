import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useStaticQuery, graphql } from 'gatsby';

import PageRow from "./page-row"
import StaticImage from './static-image';
import Lottie from "lottie-react";

import * as hearts from './hearts.json'

export default function Teacher() {
    const data = useStaticQuery(graphql`
        query Teacher {
            datoCmsTeacher {
                heading
                image {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      forceBlurhash: false
                    )
                }
                textNode {
                    childMarkdownRemark {
                        html
                    }
                }
                linkText
                linkUrl
            }
        }
    `);

    const { datoCmsTeacher } = data;
    const { html } = datoCmsTeacher.textNode.childMarkdownRemark;

    return (
        <PageRow
            name={datoCmsTeacher.heading}
            text={() => (
                <Box sx={{ position: 'relative' }}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: html
                        }}
                    />
                    <Lottie
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: '-1',
                        }}
                        animationData={hearts}
                    />
                </Box>
            )}
            linkText={datoCmsTeacher.linkText}
            linkUrl={datoCmsTeacher.linkUrl}
        >
            <Grid
                container
                justifyContent={{ xs: "center", md: "flex-start" }}
            >
                <Grid item>
                    <StaticImage
                        round
                        data={datoCmsTeacher.image.gatsbyImageData}
                    />
                </Grid>
            </Grid>
        </PageRow>
    );
}
