import React from 'react';

import ReactPlayer from 'react-player/vimeo'

export default function Video(props) {
    const { url } = props;
    return (
        <ReactPlayer
            url={url}
            controls={true}
            loop={false}
            playing={false}
            volume={1}
            pip={false}
            width="100%"
            height="100%"
            style={{
                aspectRatio: '16/9',
            }}
            config={{
                vimeo: {
                    pip: false,
                    autoplay: false,
                    background: false,
                    byline: true,
                    controls: true,
                    loop: false,
                    playsinline: true,
                    portrait: true,
                    responsive: true,
                    title: true,
                    transparent: true,
                }
            }}
         />
    );
}
