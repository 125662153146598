import React from 'react';
import Grid from '@mui/material/Grid';

import TextClamp from 'react-string-clamp';

import { StaticImage } from 'gatsby-plugin-image';

import { useStaticQuery, graphql } from 'gatsby';

import Lottie from 'lottie-react';

import Box from '@mui/material/Box';

import * as Squigs from './squigs.json';

import PageRow from './page-row';
import Video from './video';

const URL= 'https://player.vimeo.com/video/765000160?h=b430feb574&cache=false';

export default function Mission() {
    const data = useStaticQuery(graphql`
        query Mission {
            datoCmsMission {
                heading
                missionStatement
                missionStatementNode {
                    childMarkdownRemark {
                        html
                        excerpt
                    }
                }
                linkText
                linkUrl
            }
        }
    `);

    const { datoCmsMission } = data;
    const { html } = datoCmsMission.missionStatementNode.childMarkdownRemark

    return (
        <PageRow
            reverse
            html
            name={datoCmsMission.heading}
            text={() => (
                <p
                    dangerouslySetInnerHTML={{
                        __html: html
                    }}
                />
            )}
        >
            <Grid
                container
                justifyContent={{ xs: "flex-start", sm: "flex-end"}}
                alignItems="stretch"
            >
                <Grid item
                    sx={{
                        width: '100%',
                        aspectRatio: '16/9'
                    }}
                >
                    <Box sx={{ position: 'relative', height: '200px', maxHeight: '400px', }}>
                        <Video url={URL} />
                        <Box sx={{
                            left: 0,
                            top: {xs: '430px', sm: '0px'},
                            position: 'absolute',
                            zIndex: '-1',
                        }}>
                            <Lottie animationData={Squigs} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
        </PageRow>
    );
}
