import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Lottie from 'lottie-react';
import * as rainbow from './rainbow.json';

import { useStaticQuery, graphql } from 'gatsby';

import PageRow from "./page-row"
import StaticImage from "./static-image"

export default function Tuition() {
    const data = useStaticQuery(graphql`
        query Tuition {
            datoCmsTuition {
                heading
                image {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      forceBlurhash: false
                    )
                }
                textNode {
                    childMarkdownRemark {
                        html
                    }
                }
                linkText
                linkUrl
            }
        }
    `);

    const [ start, setStart ] = useState(false);
    const { datoCmsTuition } = data;

    const { html } = datoCmsTuition.textNode.childMarkdownRemark;

    return (
        <PageRow
            reverse
            name={datoCmsTuition.heading}
            text={() => (
                <Box sx={{ position: 'relative' }}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: html
                        }}
                    />
                    <Lottie
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: '-1',
                            maxWidth: '375px',
                        }}
                        animationData={rainbow}
                    />
                </Box>
            )}
            linkText={datoCmsTuition.linkText}
            linkUrl={datoCmsTuition.linkUrl}
        >
            <Grid
                container
                justifyContent={{ xs: "center", sm: "flex-end" }}
            >
                <Grid item>
                    <StaticImage
                        data={datoCmsTuition.image.gatsbyImageData}
                    />
                </Grid>
            </Grid>
        </PageRow>
    );
}
