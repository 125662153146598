import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextClamp from 'react-string-clamp';
import Link from '@mui/material/Link';

import Box from '@mui/material/Box';

const PageRow = ({ name, text, linkText, linkUrl, reverse = false, children }) => {

    const [ width, setWidth ] = useState(null);

    useEffect(() => {
        let width = window.matchMedia("(min-width: 1536px)").matches ? 'xl' : null;
        if (!width) {
            width = window.matchMedia("(min-width: 1200px)").matches ? 'lg' : null;
        }
        if (!width) {
            width = window.matchMedia("(min-width: 900px)").matches ? 'md' : null;
        }
        if (!width) {
            width = window.matchMedia("(min-width: 600px)").matches ? 'sm' : null;
        }
        if (!width) {
            width = window.matchMedia("(min-width: 0px)").matches ? 'xs' : null;
        }

        setWidth(width);
    }, []);

    let lines = null;
    switch (width) {
        case 'xl':
            lines = 14;
            break;
        case 'lg':
            lines = 14;
            break;
        case 'md':
            lines = 11;
            break;
        case 'sm':
            lines = 5;
            break;
        case 'xs':
            lines = 5;
            break;
        default:
            lines= 5;
            break;
    }

  return (
  <Grid
     container
     direction="row"
     alignItems="center"
     alignContent="center"
     justifyContent="center"
     sx={{
         margin: '0 auto',
         marginTop: { xs: '24px', md: '24px' },
     }}
  >
    <Grid item xs={12} sm={9} md={12}>
      <Typography variant="h5">{name.toUpperCase()}</Typography>
    </Grid>
    <Grid container
        direction={reverse ? 'row-reverse' : 'row'}
        rowSpacing={{ xs: 2, md: 0}}
        columnSpacing={{ xs: 0, md: 2}}
        sx={{
        }}
    >
        <Grid
            item
            xs={12}
            md={7}
            sx={{
                marginTop: { xs: '0', md: '12px' }
            }}
        >
          {children}
        </Grid>

        <Grid
            item
            xs={12}
            sm={9}
            md={5}
            sx={{
                backgrounColor: 'rgba(255, 255, 255, 0.5)',
                marginTop: { xs: '12px', md: '12px' },
            }}
        >
          {typeof text === 'function' ?
              text()
            :
              <TextClamp
                text={typeof text === 'function' ? text() : text}
                lines={lines}
              />
          }


          {linkText &&
              <Grid
                container
                alignContent="center"
                alignItems="center"
                sx={{
                    marginTop: '12px',
                    verticalAlign: 'middle',
                    textDecoration: 'underline',
                }}
              >
                <Grid item>
                  <Link
                    href={linkUrl}
                    style={{ paddingBottom: '8px', textDecoration: 'none', verticalAlign: 'middle' }}
                  >
                    {linkText}
                  </Link>
                  <i
                    data-eva="arrow-forward-outline"
                    style={{verticalAlign: 'top' }}
                  />
                </Grid>
              </Grid>
          }
        </Grid>
      </Grid>
  </Grid>
)
}

export default PageRow;
