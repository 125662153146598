import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import PageRow from "../components/page-row"
import Typography from '@mui/material/Typography';

import { StaticImage } from 'gatsby-plugin-image';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Lottie from 'lottie-react';
import * as Pulse from './pulse.json';

import LocalStaticImage from './static-image';

export default function Location() {
    const data = useStaticQuery(graphql`
        query Location {
            datoCmsLocation {
                image {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      forceBlurhash: false
                    )
                }
                textNode {
                    childMarkdownRemark {
                        html
                    }
                }
                heading
                address
                city
                state
                postal
            }
        }
    `);

    const { datoCmsLocation } = data;

    const {
        image,
        textNode,
        address,
        city,
        state,
        postal,
    } = datoCmsLocation;

    const { html } = textNode.childMarkdownRemark;

    return (
        <PageRow
            reverse
            name={datoCmsLocation.heading}
            text={() => {
                return (
                <Box sx={{ position: 'relative'}}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: html
                        }}
                    />
                    <Typography variant="p">
                        {address}
                    </Typography>
                    <br />
                    <Typography
                        variant="p"
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        {city}
                    </Typography>,&nbsp;
                    <Typography
                        variant="p"
                        style={{
                            display: 'inline-block'
                        }}
                    >
                        {state}
                    </Typography>&nbsp;
                    <Typography
                        variant="p"
                        style={{
                            display: 'inline-block'
                        }}
                    >
                        {postal}
                    </Typography>
                    <Box sx={{
                        position: 'absolute',
                        zIndex: '-1',
                        top: '0',
                    }}
                    >

                        <Box sx={{
                            position: 'relative',
                        }}>

                            <StaticImage
                                style={{
                                    width: '330px',
                                }}
                                src="../images/vashon_island.png"
                                height="200px"
                            />
                            <Box sx={{
                                position: 'absolute',
                            }}>
                                <p
                                    style={{
                                        marginTop: '-180px',
                                        marginLeft: '44px',
                                    }}
                                >🍄</p>
                                <Lottie
                                    style={{
                                        marginTop: '-315px',
                                        marginLeft: '-220px',
                                    }}
                                    animationData={Pulse}
                                />
                            </Box>
                        </Box>
                    </Box>
                 </Box>
                );
            }}
        >
            <Grid
                container
                alignItems="flex-end"
                justifyContent={{ xs: "center", md: "flex-end" }}
            >
                <Grid item>
                    <LocalStaticImage
                        data={image.gatsbyImageData}
                    />
                </Grid>
            </Grid>
        </PageRow>
    );
}
