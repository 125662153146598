import React from 'react';

import Box from '@mui/material/Box';
import { graphql } from 'gatsby'

import Grid from '@mui/material/Grid';

import Hero from '../components/hero';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Mission from "../components/mission"
import Location from "../components/location"
import Teacher from "../components/teacher"
import Tuition from "../components/tuition"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Grid
        container
        direction="column"
        sx={{
            marginTop: '0px',
            backgroundColor: 'rgba(255, 255, 255, .8)',
        }}
    >
      <Grid item xs={12}>
        <Mission />
      </Grid>
      <Grid item xs={12}>
        <Location />
      </Grid>
      <Grid item xs={12}>
        <Teacher />
      </Grid>
      <Grid item xs={12}>
        <Tuition />
      </Grid>
    </Grid>
  </Layout>
)

export const Head = () => (<Seo />);
export default IndexPage
